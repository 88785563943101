import React from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import ContactForm from "../../components/ContactForm";
import ContactInfo from "../../components/ContactInfo";

const IndexPage = ({location, data}) => {

    const locale = getLocaleFromPath(location.pathname);

    return (
        <Layout location={location}>
            <div className="MainContent">
                <section id="contact">
                    <div className="container">
                        <h2>Kontakt</h2>
                        <div className="c40p">
                            <ContactInfo locale={locale}/>
                        </div>
                        <div className="c60p">
                            <ContactForm title="Máte otázky?" locale={locale}/>
                        </div>
                        <div className="cleaner">&nbsp;</div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default withI18n()(IndexPage);

